<template>
  <div>
    <Header ref="header"/>

    <div class="user-content clearfix">
      <div class="user-side" ectype="userSide">
        <div class="user-mod user-perinfo">
          <div class="profile clearfix">
            <div class="avatar">
              <a href="javascript:void(0);" class="u-pic">
                <img src="@/assets/images/touxiang.jpg" alt="">
              </a>
            </div>
            <div class="name">
              <h2>{{userInfo.userId}}</h2>
              <div>{{userInfo.userAccount}}</div>
              <div>{{userInfo.userName}}</div>
              <div>{{userInfo.companyName}}</div>
            </div>
          </div>
        </div>
        <div class="user-mod">

          <div class="side-menu">
            <dl>
              <dt><i class="square"></i><span>订单中心</span></dt>
              <dd style="display: block;">
                <p>
                  <router-link :to="{path:'/orderList'}">我的订单</router-link>
                </p>
              </dd>
            </dl>
            <dl>
              <dt><i class="square"></i><span>会员中心</span></dt>
              <dd>
                <p>
                  <router-link :to="{path:'/accountSafe'}">账户安全</router-link>
                </p>
                <p>
                  <router-link :to="{path:'/cart'}">购物车</router-link>
                </p>
              </dd>
            </dl>
<!--            <dl>-->
<!--              <dt><i class="square"></i><span>下载中心</span></dt>-->
<!--              <dd>-->
<!--                <p>-->
<!--                  <router-link :to="{path:'/QCReport'}">-->
<!--                    质检报告-->
<!--                  </router-link>-->
<!--                </p>-->
<!--              </dd>-->
<!--            </dl>-->
          </div>
        </div>
      </div>
      <div class="user-main">
        <div class="user-mod" style="min-height: 953px;">
          <router-view/>
        </div>

<!--        <div class="user-mod" style="min-height: 953px;">-->
<!--          <div class="user-section">-->
<!--            <div class="user-title">-->
<!--              <h2>我的订单</h2>-->
<!--              <a href="user.php?act=order_list" class="more">查看所有订单</a>-->
<!--            </div>-->
<!--            <div class="user-index-order-list">-->
<!--              <div class="no_records">-->
<!--                <i class="no_icon"></i>-->
<!--                <div class="no_info no_info_line">-->
<!--                  <h3>主人，您近期还没有购买任何商品哟~</h3>-->
<!--                  <div class="no_btn">-->
<!--                    <a href="index.php" class="sc-btn sc-red-btn">去逛商城</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "Index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
}
</script>

<style>
@import "../../assets/style/user.css";
</style>
<style scoped>
.name div{
  font-size: 13px;
}
</style>
